<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>      
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Configuración de timbres'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <expandable-item 
                                    :item="item"
                                    :key="item.id" 
                                    @detalle="detalle"
                                    @abrirModalUpdate="abrirModalUpdate"
                                    @pagar="pagar"
                                >
                                </expandable-item>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="900px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" class="d-flex justify-center my-10" v-if="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-if="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Cliente"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Registros patronales" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Registro patronal"
                                                    :items="itemsRegistrosPatronales"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="configuracion.registro_patronal_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                    :readonly="accion =='update'"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Razón social" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Razón social" 
                                                    class="pa-0 ma-0" 
                                                    v-model="configuracion.razon_social" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="RFC" rules="required|rfc">
                                                <v-text-field 
                                                    outlined 
                                                    label="RFC" 
                                                    class="pa-0 ma-0" 
                                                    maxlength="13"
                                                    v-model="configuracion.rfc" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                               
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Régimen Fiscal" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Régimen Fiscal"
                                                    :items="regimenesFiscales"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="configuracion.SAT_regimen_fiscal_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Uso CFDI" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Uso CFDI"
                                                    :items="itemsUsosCFDI"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="configuracion.SAT_uso_cfdi_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>





                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0 modal-subtitle">
                                            Información Fiscal
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Código postal"
                                                    v-model="busca_cp"
                                                    :error-messages="errors"
                                                    @keyup.enter="busca_codigos()"
                                                    v-on:keydown.tab="busca_codigos()"
                                                    v-mask="'#####'"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                outlined
                                                label="Estado"
                                                v-model="estado"
                                                disabled
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                outlined
                                                label="Municipio"
                                                v-model="municipio"
                                                disabled
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Dirección" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Dirección"
                                                    v-model="configuracion.direccion"
                                                    required
                                                    :error-messages="errors"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="colonias"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Colonia"
                                                    persistent-hint
                                                    v-model="configuracion.colonia_id"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Correo" rules="required|email">
                                                <v-text-field
                                                    outlined
                                                    label="Correo"
                                                    v-model="configuracion.email"
                                                    required
                                                    :error-messages="errors"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Teléfono" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Teléfono"
                                                    v-model.number="configuracion.telefono"
                                                    :error-messages="errors"
                                                    auto
                                                    v-mask="'(###) ###-####'"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>




                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0 modal-subtitle">
                                            Información de contacto
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" class="py-0">
                                            <v-text-field
                                                outlined
                                                label="Nombre"
                                                v-model="configuracion.nombre_contacto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" class="py-0">
                                            <v-text-field
                                                outlined
                                                label="Apellido"
                                                v-model="configuracion.apellido_contacto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" class="py-0">
                                            <v-text-field
                                                outlined
                                                label="Teléfono"
                                                v-model.number="configuracion.telefono_contacto"
                                                v-mask="'(###) ###-####'"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Email" rules="email">
                                                <v-text-field
                                                    outlined
                                                    label="Email"
                                                    v-model="configuracion.email_contacto"
                                                    :error-messages="errors"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>


            <template>
                <v-dialog v-model="dialogWizard" persistent max-width="900px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="formWizard" v-slot="{ invalid }">
                            <v-card-title class="d-flex justify-end">
                                <div class="background-transparent">
                                    <v-btn
                                        @click="cerrarWizard()"
                                        small
                                        icon
                                        text
                                        :ripple="false" 
                                        class="v-btn-cerrar mr-2"
                                    >
                                        <v-icon class="icono-cerrar"></v-icon>
                                    </v-btn>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" class="d-flex justify-center my-10" v-if="isLoadingWizard">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-if="!isLoadingWizard" grid-list-md id="contenedor">
                                    <div>
                                        
                                        <div>
                                            <span class="titleWizard" v-if="tabs==0">Elige un paquete de timbres</span>
                                            <span class="titleWizard" v-if="tabs==1">Datos de facturación</span>
                                            <span class="titleWizard" v-if="tabs==2">Tipo de pago</span>
                                        </div>

                                        <v-tabs
                                            v-model="tabs"
                                            fixed-tabs
                                            background-color="transparent"
                                        >
                                            <v-tabs-slider color="transparent"></v-tabs-slider>
                                            
                                            <v-tab  class="pa-0 " >
                                                <span :class="tabs==1 || tabs == 2?'tabTextDisable':'tabText'" class="tabText"></span>
                                            </v-tab>
                                            <v-tab  class="pa-0 " >
                                                <span :class="tabs==0 || tabs == 2?'tabTextDisable':'tabText'" class="tabText"></span>
                                            </v-tab>
                                            <v-tab  class="pa-0 " >
                                                <span :class="tabs==0 || tabs == 1?'tabTextDisable':'tabText'" class="tabText"></span>
                                            </v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="tabs" class="mb-10">
                                            <v-tab-item :key="0">
                                                <v-col class="my-10" cols="12" xs="12" sm="12" md="12" style="width:100%">
                                                    
                                                    <div v-if="loadingPaquetes" style="width:800px">
                                                        <div id="padre" class="leonardo d-flex justify-center my-10" >
                                                            <div id="loading">
                                                                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <v-row v-if="!loadingPaquetes" class="ma-0 pa-0">
                                                        <v-col v-for="(pack, index) in itemsPaquetes" :key="pack.id" cols="12" xs="12" sm="6" md="6" class="pb-0 ma-0">
                                                            <Paquete
                                                                :claseColor="index%2 == 0 ? 'color-azul-claro':'color-azul-oscuro'"
                                                                :paquete="pack"
                                                                @datosPaquete="btnPaquete"
                                                            ></Paquete>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-tab-item>
                                            <v-tab-item :key="1">
                                                <v-row class="pa-0 ma-0 mt-4">
                                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Cliente"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            no-data-text="Datos no disponibles"
                                                            readonly
                                                        />
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Registro patronal"
                                                            :items="itemsRegistrosPatronales"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="configuracion.registro_patronal_id"
                                                            class="pa-0 ma-0"
                                                            no-data-text="Datos no disponibles"
                                                            readonly
                                                        >
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                        <v-text-field 
                                                            outlined
                                                            label="Razón social" 
                                                            class="pa-0 ma-0" 
                                                            v-model="configuracion.razon_social" 
                                                            readonly
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                        <v-text-field 
                                                            outlined 
                                                            label="RFC" 
                                                            class="pa-0 ma-0" 
                                                            maxlength="13"
                                                            v-model="configuracion.rfc"
                                                            readonly
                                                        >                                               
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Régimen Fiscal"
                                                            :items="regimenesFiscales"
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="configuracion.SAT_regimen_fiscal_id"
                                                            class="pa-0 ma-0"
                                                            no-data-text="Datos no disponibles"
                                                            readonly
                                                        >
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Uso CFDI"
                                                            :items="itemsUsosCFDI"
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="configuracion.SAT_uso_cfdi_id"
                                                            class="pa-0 ma-0"
                                                            no-data-text="Datos no disponibles"
                                                            readonly
                                                        >
                                                        </v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0 modal-subtitle">
                                                        Información Fiscal
                                                    </v-col>

                                                    <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Código postal"
                                                            v-model="busca_cp"
                                                            @keyup.enter="busca_codigos()"
                                                            v-on:keydown.tab="busca_codigos()"
                                                            v-mask="'#####'"
                                                            readonly
                                                        />
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Estado"
                                                            v-model="estado"
                                                            readonly
                                                        />
                                                    </v-col>

                                                    <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Municipio"
                                                            v-model="municipio"
                                                            readonly
                                                        />
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Dirección"
                                                            v-model="configuracion.direccion"
                                                            readonly
                                                        />
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="colonias"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Colonia"
                                                            persistent-hint
                                                            v-model="configuracion.colonia_id"
                                                            no-data-text="Datos no disponibles"
                                                            readonly
                                                        />
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Correo"
                                                            v-model="configuracion.email"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Teléfono"
                                                            v-model.number="configuracion.telefono"
                                                            auto
                                                            v-mask="'(###) ###-####'"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0 modal-subtitle">
                                                        Información de contacto
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Nombre"
                                                            v-model="configuracion.nombre_contacto"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Apellido"
                                                            v-model="configuracion.apellido_contacto"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Teléfono"
                                                            v-model.number="configuracion.telefono_contacto"
                                                            v-mask="'(###) ###-####'"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" class="py-0">
                                                        <v-text-field
                                                            outlined
                                                            label="Email"
                                                            v-model="configuracion.email_contacto"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-tab-item>
                                            <v-tab-item :key="2">
                                                <v-row class="pa-0 ma-0 d-flex justify-center">
                                                    <div>

                                                        <div class="div-total">
                                                            <div class="div-total-contenedor">
                                                                <table class="div-total-tabla">
                                                                    <template v-if="itemPago.paquete_timbres_id == null">
                                                                        <tr class="div-total-tabla-tr-null">
                                                                            <td class="pa-5 ma-5 div-total-tabla-td-null">
                                                                                Sin paquete
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-else>
                                                                        <tr class="div-total-tabla-tr">
                                                                            <td class="div-total-tabla-td-descripcion">Producto:</td>
                                                                            <td class="div-total-tabla-td-valor2">{{itemPago.paqueteNombre}}</td>
                                                                        </tr>
                                                                        <tr class="div-total-tabla-tr">
                                                                            <td class="div-total-tabla-td-descripcion">Precio:</td>
                                                                            <td class="div-total-tabla-td-valor">{{formatNum(itemPago.paquetePrecio)}}</td>
                                                                        </tr>
                                                                        <tr class="div-total-tabla-tr">
                                                                            <td class="div-total-tabla-td-descripcion">I.V.A.:</td>
                                                                            <td class="div-total-tabla-td-valor">{{formatNum(iva)}}</td>
                                                                        </tr>
                                                                        <tr class="div-total-tabla-tr">
                                                                            <td class="div-total-tabla-td-descripcion pt-3">Monto total a pagar:</td>
                                                                            <td class="div-total-tabla-td-valor3 pt-3">{{formatNum(montoTotal)}}</td>
                                                                        </tr>
                                                                    </template>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <v-radio-group
                                                                v-model="tipoPago"
                                                                column
                                                            >
                                                                <v-radio
                                                                    label="Transferencia"
                                                                    value="transferencia"
                                                                ></v-radio>
                                                                <v-radio
                                                                    label="Pago con tarjeta"
                                                                    value="tarjeta"
                                                                ></v-radio>
                                                            </v-radio-group>
                                                        </div>


                                                        <template v-if="tipoPago == 'transferencia'">
                                                            <div class="transfencia mb-10">
                                                                <div class="tranferencia-contenedor">
                                                                    <h4 class="transferencia-titulo-general">Datos para la transferencia</h4>

                                                                    <div class="transferencia-titulo mt-3">Empresa</div>
                                                                    <div class="transferencia-descripcion">TERRACOTA MID S.A. DE C.V. RFC: TMI-1607206-G8</div>

                                                                    <div class="transferencia-titulo mt-3">Banco</div>
                                                                    <div class="transferencia-descripcion">BANORTE</div>

                                                                    <div class="transferencia-titulo mt-3">Cuenta</div>
                                                                    <div class="transferencia-descripcion">0467199426</div>

                                                                    <div class="transferencia-titulo mt-3">CLABE INTERBANCARIA</div>
                                                                    <div class="transferencia-descripcion">072910004671994261</div>
                                                                </div>
                                                            </div>
                                                            <ValidationProvider v-slot="{ errors }" name="Archivo" rules="required">
                                                                <v-file-input
                                                                    outlined
                                                                    label="Seleccionar archivo"
                                                                    v-model="archivo"
                                                                    prepend-icon=""
                                                                    append-icon="attach_file"
                                                                    id="fileDocument"
                                                                    @click:append="open"
                                                                    :error-messages="errors" 
                                                                >
                                                                </v-file-input>
                                                            </ValidationProvider>
                                                        </template>


                                                        <template v-if="tipoPago == 'tarjeta'">
                                                            <v-col cols="12" xs="12" sm="12" md="12" style="width:350px">
                                                                <ValidationProvider v-slot="{ errors }" name="Número de tarjeta" rules="required">
                                                                    <v-text-field 
                                                                        outlined
                                                                        label="Número de tarjeta" 
                                                                        class="pa-0 ma-0" 
                                                                        v-model="tarjeta.numero"
                                                                        v-mask="['#### #### #### ####']"
                                                                        :error-messages="errors" 
                                                                    >
                                                                    </v-text-field>
                                                                </ValidationProvider>
                                                                <ValidationProvider v-slot="{ errors }" name="CVC" rules="required">
                                                                    <v-text-field 
                                                                        outlined
                                                                        label="CVC" 
                                                                        class="pa-0 ma-0" 
                                                                        v-model="tarjeta.cvc"
                                                                        v-mask="'####'"
                                                                        :error-messages="errors" 
                                                                    >
                                                                    </v-text-field>
                                                                </ValidationProvider>
                                                                <ValidationProvider v-slot="{ errors }" name="MM/AA" rules="required">
                                                                    <v-text-field 
                                                                        outlined
                                                                        label="MM/AA" 
                                                                        class="pa-0 ma-0" 
                                                                        v-model="tarjeta.mmaa"
                                                                        v-mask="'##/##'"
                                                                        :error-messages="errors" 
                                                                    >
                                                                    </v-text-field>
                                                                </ValidationProvider>
                                                            </v-col>
                                                        </template>
                                                    </div>
                                                </v-row>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </div>
                                </v-container>
                            </v-card-text>
                            <v-card-actions v-if="tabs==1 " class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="regresar(0)" 
                                    :disabled="isSaving"
                                >
                                    Regresar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="btnSiguiente()" 
                                    :loading="isSaving"
                                >
                                    Siguiente
                                </v-btn>
                            </v-card-actions>
                            <v-card-actions v-if="tabs==2 " class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="regresar(1)" 
                                    :disabled="isSaving"
                                >
                                    Regresar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardarWizard()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>

//Componentes
import Datatable from '@/components/datatable/Datatable.vue';
import Paquete from '@/components/Paquete';
import ExpandableItem from '@/components/ExpandableItemConfiguracionTimbres.vue';

//API's
import apiConfiguracionTimbres from '@/api/nominas/configurarTimbres';
import catalogoApis from "@/api/catalogos";
import clientesApi from "@/api/clientes";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiRegimenFiscal from '@/api/nominas/regimenFiscal';
import queries from '@/queries/ConfigurarTimbres';

//Utilerias
import Notify from '@/plugins/notify';


export default {
    components: {
        'data-table': Datatable,
        Paquete,
        ExpandableItem
    },
    data() {
        return {
            tabs: 0,
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: false
                },
                {
                    label: 'Timbres comprados',
                    name: 'timbres_comrados',
                    filterable: false
                },
                {
                    label: 'Timbres usados',
                    name: 'timbres_usados',
                    filterable: false,
                },
                {
                    label:'Timbres disponibles',
                    name:'timbres_disponibles',
                    filterable:false
                },
                {
                    label:'Último movimiento',
                    name:'ultimo_movimiento',
                    filterable:false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {
                cliente_id  : null,
                paginate    : true,
                activo      : true,
            },
            url             : 'configuracion-timbres/find',
            isLoading       : true,
            isLoadingModal  : false,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            configuracion           : {
                id                      : null,
                cliente_id              : null,
                registro_patronal_id    : null,
                razon_social            : null,
                rfc                     : null,
                codigo_postal           : null,
                email                   : null,
                telefono                : null,
                SAT_regimen_fiscal_id   : null,
                SAT_uso_cfdi_id         : null,

                cp_id                   : null,
                estado_id               : null,
                municipio_id            : null,
                colonia_id              : null,
                direccion               : null,

                nombre_contacto         : null,
                apellido_contacto       : null,
                telefono_contacto       : null,
                email_contacto          : null,
            },
            datosLogin                  : null,
            rol                         : null,
            cliente_value               : null,
            accion                      : null,
            tituloModal                 : null,
            dialog                      : false,
            isSaving                    : false,
            imagen                      : '',
            regimenesFiscales           : [],
            itemsUsosCFDI               : [],


            dialogWizard                : false,
            isLoadingWizard             : false,
            loadingPaquetes             : false,
            itemsPaquetes               : [],
            tipoPago                    : 'transferencia',
            archivo                     : null,
            tarjeta                     : {
                numero                  : null,
                cvc                     : null,
                mmaa                    : null,
            },
            busca_cp                    : null,
            estado                      : "",
            municipio                   : "",
            colonias                    : [],
            clientes                    : [],
            itemsRegistrosPatronales    : [],
            itemPago                    : {
                paquete_timbres_id      : null,
                paqueteNombre           : null,
                paquetePrecio           : null,
                iva_porcentaje          : null,
                datos_facturacion_id    : null,
            }

        }
    },
    watch: {
        cliente_value: function(val) {
            this.getRegistrosPatronales(val);
        },
        busca_cp: function(val){
            if(val != null){
                if(val.length == 5){
                    this.busca_codigos();
                }
                else{
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                }
            }
        }
    },
    computed:{
        montoTotal(){

            if(this.itemPago.paquetePrecio == null || this.itemPago.iva_porcentaje == null) {
                return;
            }

            return parseFloat(this.itemPago.paquetePrecio) + (parseFloat(this.itemPago.iva_porcentaje) * parseFloat(this.itemPago.paquetePrecio) / 100)
        },
        iva(){
            if(this.itemPago.paquetePrecio == null || this.itemPago.iva_porcentaje == null) {
                return;
            }
            return parseFloat(this.itemPago.iva_porcentaje) * parseFloat(this.itemPago.paquetePrecio) / 100
        }
    },

    methods: {
        open(){
            document.getElementById("fileDocument").click();
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        getUsoCFDI(){
            let parametros = {
                activo      : true, 
                paginate    : false,
                order       : 'asc'
            }
            apiConfiguracionTimbres.getUsoCFDI(parametros).then(response =>{
                this.itemsUsosCFDI = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar el catálogo Usos CFDI");
            });
        },
        getRegimenesFiscales(){
            let parametros = {
                activo      : true, 
                paginate    : false,
                order       : 'asc'
            }
            apiRegimenFiscal.find(parametros).then(response =>{
                this.regimenesFiscales = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast(err.response.data.error);
            });
        },
        async getClientes() {
            let param = { 
                activo: true, 
                paginate: false 
            };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },
        async getRegistrosPatronales(clienteId) {
            if(clienteId == null){
                return;
            }

            let param = { 
                    activo: true, 
                    paginate: false, 
                    cliente_id: clienteId 
                };
            await apiRegistroPatronal.find(param).then(response => {
                this.itemsRegistrosPatronales = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los registros patronales.");
            });
            
        },
        busca_codigos() {
            let self = this;
            let params = {
                activo: true,
                nombre: this.busca_cp,
                include_padres: true,
            };
            catalogoApis.getCP(params).then((response) => {
                let result = response.data;
                if (result.length == 0) {
                    Notify.ErrorToast("No se encontro el Código postal.");
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                    return;
                }
                this.estado = result[0].nomb_estado;
                this.municipio = result[0].nomb_mun;
                this.configuracion.estado_id = result[0].estado_id;
                this.configuracion.municipio_id = result[0].municipio_id;
                this.configuracion.cp_id = result[0].id;

                catalogoApis.getColonias({ codigo_postal_id: result[0].id, order: "asc", paginate: false }).then((response) => {
                    self.colonias = response.data;
                    if (self.accion == "update") {
                        self.finaliza_carga = true;
                    }
                })
                .catch((e) => {
                        Notify.ErrorToast(
                            "Se presento un problema al cargar las colonias."
                        );
                    return;
                });
            })
            .catch((e) => {
                console.log(e);
                Notify.ErrorToast(
                    "Ocurrió un error al cargar los estados y ciudades."
                );
            });
        },
        async getCP(id) {
            await catalogoApis.getCodigoPostal(id).then((response) => {
                this.busca_cp = response.nombre;
                this.busca_codigos();
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los codigos postales.");
            });
        },
        async abrirModal(accion, data = null){
            this.accion         = accion;
            this.isLoadingModal = true;
            this.getRegimenesFiscales();
            this.getUsoCFDI();

            if(this.rol == 'root'){
                this.getClientes();
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
            }

            if(accion == "add"){
                this.tituloModal    = "Nuevos datos para facturación";
                this.imagen         = "/static/icon/configuracionTimbres.png";
                
            } else {
                this.tituloModal        = "Modificar datos para facturación";
                this.imagen             = "/static/icon/configuracionTimbres.png";
                this.configuracion.id                       = data.id;
                this.cliente_value                          = data.cliente_id;
                this.configuracion.registro_patronal_id     = data.registro_patronal_id;
                this.configuracion.razon_social             = data.razon_social;
                this.configuracion.rfc                      = data.rfc;
                this.configuracion.email                    = data.email;
                this.configuracion.telefono                 = data.telefono;
                this.configuracion.SAT_regimen_fiscal_id    = data.SAT_regimen_fiscal_id;
                this.configuracion.SAT_uso_cfdi_id          = data.SAT_uso_cfdi_id;
                this.configuracion.colonia_id               = data.colonia_id;
                this.configuracion.direccion                = data.direccion;

                this.configuracion.nombre_contacto          = data.nombre_contacto;
                this.configuracion.apellido_contacto        = data.apellido_contacto;
                this.configuracion.telefono_contacto        = data.telefono_contacto;
                this.configuracion.email_contacto           = data.email_contacto;
                await this.getCP(data.cp_id);
            }
            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        async abrirModalUpdate(data){
            this.accion         = 'update';
            this.isLoadingModal = true;
            this.getRegimenesFiscales();
            this.getUsoCFDI();


            if(this.rol == 'root'){
                this.getClientes();
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
            }

            if(this.accion == "add"){
                this.tituloModal    = "Nuevos datos para facturación";
                this.imagen         = "/static/icon/configuracionTimbres.png";
                
            } else {
                this.tituloModal        = "Modificar datos para facturación";
                this.imagen             = "/static/icon/configuracionTimbres.png";
                this.configuracion.id                       = data.id;
                this.cliente_value                          = data.cliente_id;
                this.configuracion.registro_patronal_id     = data.registro_patronal_id;
                this.configuracion.razon_social             = data.razon_social;
                this.configuracion.rfc                      = data.rfc;
                this.configuracion.email                    = data.email;
                this.configuracion.telefono                 = data.telefono;
                this.configuracion.SAT_regimen_fiscal_id    = data.SAT_regimen_fiscal_id;
                this.configuracion.SAT_uso_cfdi_id          = data.SAT_uso_cfdi_id;
                this.configuracion.colonia_id               = data.colonia_id;
                this.configuracion.direccion                = data.direccion;

                this.configuracion.nombre_contacto          = data.nombre_contacto;
                this.configuracion.apellido_contacto        = data.apellido_contacto;
                this.configuracion.telefono_contacto        = data.telefono_contacto;
                this.configuracion.email_contacto           = data.email_contacto;
                await this.getCP(data.cp_id);
            }
            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.cliente_value = null;
            this.busca_cp = null;
            this.estado = null;
            this.municipio = null;
            this.tabs = 0;
            this.configuracion      = {
                id                      : null,
                cliente_id              : null,
                registro_patronal_id    : null,
                razon_social            : null,
                rfc                     : null,
                codigo_postal           : null,
                email                   : null,
                telefono                : null,
                SAT_regimen_fiscal_id   : null,
                SAT_uso_cfdi_id         : null,

                cp_id                   : null,
                estado_id               : null,
                municipio_id            : null,
                colonia_id              : null,
                direccion               : null,

                nombre_contacto         : null,
                apellido_contacto       : null,
                telefono_contacto       : null,
                email_contacto          : null,
            }
            this.itemPago               = {
                paquete_timbres_id      : null,
                paqueteNombre           : null,
                paquetePrecio           : null,
                iva_porcentaje          : null,
                datos_facturacion_id    : null
            }
            this.tarjeta                = {
                numero                  : null,
                cvc                     : null,
                mmaa                    : null,
            };
            this.archivo = null;
            this.tipoPago = "transferencia";

            this.$nextTick(() => {

                if(this.$refs.form){
                    this.$refs.form.reset();
                }
                if(this.$refs.formWizard){
                    this.$refs.formWizard.reset();
                }
            });
        },
       
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                
                this.configuracion.cliente_id = this.cliente_value;
                this.configuracion.codigo_postal = this.busca_cp;
                
                if(this.accion === "add") {
                    apiConfiguracionTimbres.guardarDatos(this.configuracion).then((response) => {
                        Notify.Success("Operación exitosa", "Los datos de facturación se guardaron satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiConfiguracionTimbres.updateDatos(this.configuracion).then((response) => {
                        Notify.Success("Operación exitosa", "Los datos de facturación se actualizaron satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        detalle(datosRegistroPatronal){
            this.$session.remove("idRegistroPatronalTimbre");
            this.$session.set('idRegistroPatronalTimbre',datosRegistroPatronal.id);
            this.$router.push('/detalleTimbres');
        },
        getPaquetes(){
            let parametros = {
                paginate: false,
                activo:true,
                order:'asc'
            }

            this.loadingPaquetes = true;
            apiConfiguracionTimbres.findPaquetesTimbres(parametros).then((response) => {
                this.loadingPaquetes = false;
                this.itemsPaquetes = response.data;
            })
            .catch(err => {
                this.loadingPaquetes = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        async pagar(data){
            this.isLoadingWizard = true;
            this.dialogWizard = true;

            //Tab paquetes
            this.getPaquetes();

            /* Tab para mostrar informacion datos de facturación */
            this.getUsoCFDI();
            this.getRegimenesFiscales();

            if(this.rol == 'root'){
                this.getClientes();
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            this.configuracion.id                       = data.id;
            this.cliente_value                          = data.cliente_id;
            this.configuracion.registro_patronal_id     = data.registro_patronal_id;
            this.configuracion.razon_social             = data.razon_social;
            this.configuracion.rfc                      = data.rfc;
            this.configuracion.email                    = data.email;
            this.configuracion.telefono                 = data.telefono;
            this.configuracion.SAT_regimen_fiscal_id    = data.SAT_regimen_fiscal_id;
            this.configuracion.SAT_uso_cfdi_id          = data.SAT_uso_cfdi_id;
            this.configuracion.colonia_id               = data.colonia_id;
            this.configuracion.direccion                = data.direccion;

            this.configuracion.nombre_contacto          = data.nombre_contacto;
            this.configuracion.apellido_contacto        = data.apellido_contacto;
            this.configuracion.telefono_contacto        = data.telefono_contacto;
            this.configuracion.email_contacto           = data.email_contacto;
            await this.getCP(data.cp_id);

            this.itemPago.datos_facturacion_id          = data.id;

            this.isLoadingWizard = false;

        },
        btnPaquete(item){
            this.tabs                           = 1;
            this.itemPago.paqueteNombre         = item.nombre;
            this.itemPago.paquetePrecio         = item.precio;
            this.itemPago.iva_porcentaje        = item.iva_porcentaje;
            this.itemPago.paquete_timbres_id    = item.id
        },
        btnSiguiente(){
            this.tabs=2
        },
        regresar(data){
            this.tabs = data;
        },
        guardarWizard(){

            this.$refs.formWizard.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(this.itemPago.paquete_timbres_id == null){
                    this.isSaving = false;
                    Notify.ErrorToast("Elije un paquete para seguir con el proceso de compra.");
                    return;
                }

                let formData = new FormData();
                formData.append('paquete_timbres_id', this.itemPago.paquete_timbres_id);
                formData.append('datos_facturacion_id', this.itemPago.datos_facturacion_id);

                if(this.tipoPago == "transferencia"){
                    formData.append('tipo_pago', 'TRANSFERENCIA');
                    formData.append('archivo', this.archivo);
                }
                else if(this.tipoPago == "tarjeta"){
                    formData.append('tipo_pago', 'PAGO_CON_TARJETA');


                    if(this.tarjeta.numero.length != 19 && this.tarjeta.numero.length != 18){
                        Notify.ErrorToast("Error en número de tarjeta.");
                        this.isSaving = false;
                        return;
                    }
                    if(this.tarjeta.cvc.length != 3 && this.tarjeta.cvc.length != 4){
                        Notify.ErrorToast("Error en cvc.");
                        this.isSaving = false;
                        return;
                    }
                    if(this.tarjeta.mmaa.length != 5){
                        Notify.ErrorToast("Error en mm/aa.");
                        this.isSaving = false;
                        return;
                    }

                    let arrayTarjetaNumero = this.tarjeta.numero.split(' ');

                    let tarjetaNumero = arrayTarjetaNumero[0]+arrayTarjetaNumero[1]+arrayTarjetaNumero[2]+arrayTarjetaNumero[3];

                    let arrayTarjetaMMAA = this.tarjeta.mmaa.split('/');

                    formData.append('numero', tarjetaNumero);
                    formData.append('cvv',this.tarjeta.cvc);
                    formData.append('mes_expiracion', arrayTarjetaMMAA[0]);
                    formData.append('anio_expiracion', arrayTarjetaMMAA[1]);

                }


                apiConfiguracionTimbres.guardarCompra(formData).then((response) => {
                    Notify.Success("Operación exitosa", "Se solicitó la compra del paquete satisfactoriamente.");
                    
                    this.cerrarWizard();
                    this.isSaving = false;
                    this.$refs.tabla.getData();
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });

            })

        },
        cerrarWizard(){
            this.dialogWizard = false;
            this.cerrarModal();
        },
        formatNum(numero){
            let num = parseFloat(numero)
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
        redireccionar(){
            this.$router.push('/dashboard');
        }
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;

        if(this.rol != 'root'){
            this.filters.cliente_id = this.datosLogin.cliente_id;
        }
    },
    mounted(){
        this.isLoading = false;
    },
}
</script>

<style scoped>
    .modal-subtitle {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1E2144;
    }

    .tabText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #1E2245;
        text-transform: none;
        background-color: #2DB9D1;
        height: 6px;
        width: 100%;
        margin: 0;
        border-radius:6px

    }
    .tabTextDisable{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #BDBDBD;
        text-transform: none;
        background-color: #828282;
        height: 1px;
        width: 100%;
        margin: 0;

    }

    .titleWizard {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #1E2245;
    }

    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }

    .transfencia {
        border: 1px solid #E0E0E0;
        border-radius: 10px;
    }
    .tranferencia-contenedor {
        margin: 20px;
    }
    .transferencia-titulo-general {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .transferencia-titulo {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;

    }
    .transferencia-descripcion {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }


    .div-total {
        border: 1px solid #E0E0E0;
        border-radius: 10px;
    }
    .div-total-contenedor {
        margin: 20px;
    }
    .div-total-tabla {
        width: 100%;
    }
    .div-total-tabla-td-descripcion {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #4E4E4E;
    }
    .div-total-tabla-td-valor {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #4E4E4E;
    }
    .div-total-tabla-td-valor2 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        color: #1E2245;
    }
    .div-total-tabla-td-valor3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #1E2245;
    }
    .div-total-tabla-td-null {
        text-align: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
    }


</style>
