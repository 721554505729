import gql from 'graphql-tag';

const usoCFDIQuery = gql`
    query usos_cfdi($numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        usos_cfdi(first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
				descripcion,
				codigo,
				activo
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const paquetesTimbres = gql`
    query paquetes_timbres($numberItems: Int!, $numberPage: Int!, $fieldOrder: String! ) {
        paquetes_timbres(first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: ASC}]) {
            data {
                id,
				clave,
				descripcion,
				timbres,
				precio,
				activo
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`
const itemsRazonesSocialesClientes = gql`
    query clientes_datos_facturacion($whereCondition:ClientesDatosFacturacionWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String! ) {
        clientes_datos_facturacion(where:$whereCondition, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: ASC}]) {
            data {
                id,
                razon_social,
				rfc,
				codigo_postal,
				espersona_fisica,
			    email,
				telefono,
				SAT_uso_cfdi_id,
				SAT_regimen_fiscal_id,				
				usoCfdi {
					codigo,
					descripcion,
					activo
				},
				regimenFiscal{
					clave,
					descripcion,
					activo
				}
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const facturacionPaquetesHistorial = gql`
    query facturacion_paquetes($whereCondition:FacturacionPaquetesWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String! ) {
        facturacion_paquetes(where:$whereCondition, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: DESC}]) {
            data {
                id,
                timbres_asignados,
				fecha_solicitud,
				fecha_aplicar_timbres,
				registroPatronal{ 
					 nombre,
					 email
				},
                clienteDatosFacturacion{
					id,
					razon_social
				}
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { usoCFDIQuery, paquetesTimbres, itemsRazonesSocialesClientes, facturacionPaquetesHistorial };

export default queries;
